// PasswordReset Page
import React, { Component } from "react";
import {
    verifyPasswordReset,
    resetPassword
} from "../../../firebase/userManagement";
import PropTypes from "prop-types";
import Layout from "../../../components/Layout";
import styles from "./PasswordReset.module.css";

class PasswordReset extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newPassword: "",
            passwordVerification: "",
            verified: "loading",
            email: ""
        };

        this.onLogoClick = this.onLogoClick.bind(this);
        this.renderForm = this.renderForm.bind(this);
        this.noForm = this.noForm.bind(this);

        this.updateByPropertyName = this.updateByPropertyName.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
    }

    static propTypes = {
        oobCode: PropTypes.string.isRequired
    };

    static defaultProps = {
        oobCode: "abc123"
    };

    componentDidMount() {
        const { oobCode } = this.props;
        verifyPasswordReset(oobCode).then(email => {
            if (email) {
                this.setState({ email, verified: "true" });
            } else {
                this.setState({ verified: "error" });
            }
        });
    }

    updateByPropertyName = (propertyName, value) => {
        this.setState({ [propertyName]: value });
    };

    onLogoClick = () => {
        this.props.history.push("/");
    };

    resetPassword = e => {
        e.preventDefault();
        const { newPassword, passwordVerification } = this.state;
        const { oobCode } = this.props;

        if (newPassword === passwordVerification) {
            resetPassword(oobCode, newPassword).then(verified => {
                this.updateByPropertyName("verified", verified);
            });
        } else {
            this.confirmPassword.setCustomValidity("Passwords do not match");
        }
    };

    noForm = () => {
        const { verified } = this.state;
        let message = "";
        switch (verified) {
            case "success":
                message = "Success!";
                break;
            case "loading":
                message = "Loading...";
                break;
            default:
                message = "An error occured, please try again.";
        }
        return <h1 className={styles.pageMessage}>{message}</h1>;
    };

    renderForm = () => {
        return (
            <div>
                <h1 className={styles.title}>Password Reset</h1>
                <form
                    onSubmit={this.resetPassword}
                    className={styles.resetForm}
                >
                    <label className={styles.label} htmlFor="password">
                        New Password:
                    </label>
                    <input
                        required
                        pattern="(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
                        maxLength="30"
                        type="password"
                        placeholder="Password"
                        className={styles.inputField}
                        onChange={event =>
                            this.updateByPropertyName(
                                "newPassword",
                                event.target.value
                            )
                        }
                    />

                    <label className={styles.label} htmlFor="password">
                        Verify Password:
                    </label>
                    <input
                        required
                        pattern="(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
                        maxLength="30"
                        type="password"
                        placeholder="Password"
                        ref={input => (this.confirmPassword = input)}
                        className={styles.inputField}
                        onChange={event =>
                            this.updateByPropertyName(
                                "passwordVerification",
                                event.target.value
                            )
                        }
                    />

                    <button type="submit" className={styles.submitButton}>
                        Submit
                    </button>
                    <p className={styles.resetTitle}>
                        {" "}
                        Password must contain:{" "}
                    </p>
                    <ul className={styles.resetList}>
                        <li className={styles.resetRules}>6 characters</li>
                        <li className={styles.resetRules}>
                            1 uppercase letter
                        </li>
                        <li className={styles.resetRules}>
                            1 lowercase letter
                        </li>
                        <li className={styles.resetRules}>1 symbol</li>
                        <li className={styles.resetRules}>1 number</li>
                    </ul>
                </form>
            </div>
        );
    };
    render() {
        return (
            <Layout title={"AXLRY - Password Reset"}>
                <div className={`${styles.page} center-container`}>
                    {this.state.verified === "true"
                        ? this.renderForm()
                        : this.noForm()}
                </div>
            </Layout>
        );
    }
}

export default PasswordReset;
